import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../components/wrapper";
import StepPagination from "../../components/stepPagination";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from "formik";
import { IFirstProps } from "../first";
import { IEntity, PaymentTypeEnum, TicketTypeEnum } from "../../types";
import {
  bankConnectSchema,
  bankDirectSchema,
  validationSchemaStep3,
} from "../../validations";
import PaymentTypes from "./paymentTypes";
import { useNavigate, useParams } from "react-router-dom";
import BankDetailsModal from "./paymentTypes/modal/BankDetailsModal";
import PaymentConnect from "./bankAccauntInfo/PaymentConnect";
import PaymentDirect from "./bankAccauntInfo/PaymentDirect";
import {
  useCreateBankAccountMutation,
  useCreateOrUpdatePaymentStripeAlternativeMutation,
  useCreateOrUpdatePaymentTicketOfficeMutation,
  useGetEventPaymentsQuery,
} from "../../qraphql/event.hooks";
import { PaymentStripeAlternativeTypeInput } from "../../../graphql/types";
import ApolloError from "../../components/apolloError";
import ScrollToFieldError from "../../components/ScrollToFieldError";
import useGetUrlLocale from "../../hooks/useGetUrlLocale";

export default function Second({
  entity,
  handleUpdateEntity,
  step,
}: IFirstProps) {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<IEntity>>(null);
  const navigate = useNavigate();
  const urlLocale = useGetUrlLocale();
  const [
    createOrUpdatePaymentStripeAlternative,
    { error: createPaymentStripeAlternativeError },
  ] = useCreateOrUpdatePaymentStripeAlternativeMutation();
  const [
    createBankAccount,
    { data: createBankAccountData, error: createBankAccountError },
  ] = useCreateBankAccountMutation();
  const [
    createOrUpdatePaymentTicketOffice,
    {
      data: createPaymentTicketOfficeData,
      error: createPaymentTicketOfficeError,
    },
  ] = useCreateOrUpdatePaymentTicketOfficeMutation();
  const {
    data: eventPaymentsData,
    loading: eventPaymentsLoading,
    refetch: refetchEventPayments,
  } = useGetEventPaymentsQuery({
    variables: { eventId: entity.id },
  });

  const eventPayment = eventPaymentsData?.eventProxy.payments[0];

  const [showBankAccountInfo, setShowBankAccountInfo] = useState(false);
  const [isBankDetailsModal, setIsBankDetailsModal] = useState(false);

  const { id, eventId } = useParams();

  const handleShowBankAccоuntInfo = (isShow: boolean) => {
    setShowBankAccountInfo(isShow);
  };

  const onSubmit = async (
    values: IEntity,
    { setSubmitting }: FormikHelpers<IEntity>
  ) => {
    if (showBankAccountInfo) {
      // check if modal stripe alternative confirmation open
      setIsBankDetailsModal(true);
    } else if (eventId) {
      // check if create stripe bank account mode
      const bankConnectValues = values.bankConnect;
      try {
        await createBankAccount({
          variables: {
            eventId: Number(eventId),
            input: {
              countryCode: bankConnectValues.country,
              currencyName: bankConnectValues.currency,
              holderName: bankConnectValues.holderName,
              iban: bankConnectValues.iban,
            },
          },
        });
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    } else if (!values.isPayOnline) {
      // check if payment is ticket office
      try {
        await createOrUpdatePaymentTicketOffice({
          variables: {
            eventId: values.id,
            input: {
              address: values.ticketAddresses,
              email: values.merchantContacts.map((item) => item.email),
              phone: values.merchantContacts.map((item) => `+${item.phone}`),
            },
          },
        });
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    } else if (
      // check if payment is stripe_alternative or stripe
      values.isPayOnline &&
      (eventPayment?.type === PaymentTypeEnum.StripeAlternative ||
        eventPayment?.stripeConnectBankAccountId)
    ) {
      navigate(`${urlLocale}/check-publish`);
      await handleUpdateEntity(values, 3);
    }
  };

  useEffect(() => {
    if (createPaymentTicketOfficeData || createBankAccountData) {
      handleUpdateEntity(formRef.current.values, 3);
      navigate(`${urlLocale}/check-publish`);
    }
    // eslint-disable-next-line
  }, [createPaymentTicketOfficeData, createBankAccountData]);

  const handleCloseBankDetailsInfo = () => {
    setIsBankDetailsModal(false);
  };

  const handleSaveBankDetailsInfo = async () => {
    const values = formRef.current.values.bankDirect;
    const inputInfo: PaymentStripeAlternativeTypeInput = {
      email: values.email,
      phone: `+${values.phone}`,
      address: values.address,
      city: values.city,
      postalCode: Number(values.postalCode),
      countryId: Number(values.countryAccount),
      countryOfTheBankAccountId: Number(values.countryResidence),
      nameOfTheBank: values.bankName,
      accountHolderName: values.holderName,
      iban: values.iban,
      feePayerType: values.isIncludeFee,
    };
    try {
      await createOrUpdatePaymentStripeAlternative({
        variables: {
          eventId: formRef.current.values.id,
          input: inputInfo,
        },
      });
      setShowBankAccountInfo(false);
      await handleUpdateEntity(formRef.current.values, 2);
    } catch (error) {
    } finally {
      handleCloseBankDetailsInfo();
    }
    await refetchEventPayments();
  };

  const isSkipStep = formRef.current?.values.tickets.every(
    (ticket) =>
      (ticket.ticketType === TicketTypeEnum.Free && ticket.active === true) ||
      (ticket.ticketType !== TicketTypeEnum.Free && ticket.active === false)
  );

  const nextStep = () => {
    if (formRef.current) {
      navigate(`${urlLocale}/check-publish`);
      handleUpdateEntity(formRef.current.values, 3);
    }
  };

  return (
    <Wrapper>
      {isBankDetailsModal && (
        <BankDetailsModal
          handleClose={handleCloseBankDetailsInfo}
          handleSave={handleSaveBankDetailsInfo}
        />
      )}
      <h1 className="fw-bold fs-1 mb-4 mb-md-5">
        {t("firstStep.createEvent")}
      </h1>
      <StepPagination step={step} />
      <Formik
        innerRef={formRef}
        initialValues={entity}
        onSubmit={onSubmit}
        validationSchema={
          eventId
            ? bankConnectSchema(t)
            : showBankAccountInfo
              ? bankDirectSchema(t)
              : validationSchemaStep3(t)
        }
        enableReinitialize
      >
        {({ isSubmitting, isValid, submitCount, values }) => {
          return (
            <FormikForm>
              <ScrollToFieldError />
              {eventId ? (
                <PaymentConnect />
              ) : showBankAccountInfo ? (
                <PaymentDirect />
              ) : (
                <PaymentTypes
                  handleShowBankAccоuntInfo={handleShowBankAccоuntInfo}
                  eventPayment={eventPayment}
                  handleUpdateEntity={handleUpdateEntity}
                  eventPaymentsLoading={eventPaymentsLoading}
                />
              )}

              <Row className="justify-content-between my-5">
                <Col xs={4} lg={2}>
                  <Button
                    variant="outline-success"
                    className="w-100 py-3 fw-normal"
                    onClick={() => {
                      handleUpdateEntity(entity, 1);
                      navigate(
                        `${urlLocale}/tickets-info/${id || values.id}/edit`
                      );
                    }}
                  >
                    {t("secondStep.back")}
                  </Button>
                </Col>
                {((values.isPayOnline &&
                  (eventPayment?.type === PaymentTypeEnum.StripeAlternative ||
                    eventPayment?.stripeConnectBankAccountId)) ||
                  !values.isPayOnline ||
                  showBankAccountInfo ||
                  eventId) && (
                  <Col xs={8} lg={4}>
                    <Button
                      variant="success"
                      className="fw-bold w-100 py-3"
                      type="submit"
                      disabled={isSubmitting || (submitCount > 0 && !isValid)}
                    >
                      {t("firstStep.next")}
                    </Button>
                  </Col>
                )}
                {isSkipStep && values.isPayOnline && (
                  <Col xs={8} lg={4}>
                    <Button
                      variant="success"
                      className="fw-bold w-100 py-3"
                      onClick={nextStep}
                    >
                      {t("firstStep.next")}
                    </Button>
                  </Col>
                )}
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
      <ApolloError networkError={createBankAccountError?.networkError} />
      <ApolloError
        networkError={createPaymentStripeAlternativeError?.networkError}
      />
      <ApolloError
        networkError={createPaymentTicketOfficeError?.networkError}
      />
    </Wrapper>
  );
}
